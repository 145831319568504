.outerHexagon {
  background: #7553ea;
}

.outerHexagon:hover {
  transform: scale(1.05);
}

.innerHexagon {
  background: white;
  inset: 1px !important;
  /* filter: 'grayscale(1)'; */
  cursor: 'pointer';
}

.innerHexagon:hover {
  filter: 'grayscale(0)';
}
