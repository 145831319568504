.ant-picker {
    padding: 7px 11px;
}

.custom-datepicker {
    max-width: max-content !important;
}

.custom-datepicker .ant-picker-cell-inner {
    color: #7553EA;
}

.custom-datepicker .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #7553EA;
}

.custom-datepicker .ant-picker-active-bar {
    background: #7553EA;
}

:where(.css-dev-only-do-not-override-1r287do).ant-picker-outlined:hover {
    border-color: #000000de;
    box-shadow: none;
}

:where(.css-dev-only-do-not-override-1r287do).ant-picker-outlined:focus-within {
    border-color: #7553EA;
    box-shadow: none;
}

:where(.css-dev-only-do-not-override-1r287do).ant-picker-outlined:focus {
    border-color: #7553EA;
    box-shadow: none;
}

:where(.css-dev-only-do-not-override-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background-color: #7553EA;
}

:where(.css-dev-only-do-not-override-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background-color: #7553EA;
}

:where(.css-dev-only-do-not-override-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before, :where(.css-dev-only-do-not-override-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before, :where(.css-dev-only-do-not-override-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
    background-color: #E6E6FF;
}

:where(.css-dev-only-do-not-override-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #7553EA;
}

:where(.css-1r287do).ant-picker-outlined:hover {
    border-color: #000000de;
    box-shadow: none;
}

:where(.css-1r287do).ant-picker-outlined:focus-within {
    border-color: #7553EA;
    box-shadow: none;
}

:where(.css-1r287do).ant-picker-outlined:focus {
    border-color: #7553EA;
    box-shadow: none;
}

:where(.css-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background-color: #7553EA;
}

:where(.css-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background-color: #7553EA;
}

:where(.css-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before, :where(.css-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before, :where(.css-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
    background-color: #E6E6FF;
}

:where(.css-1r287do).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #7553EA;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
}

.ant-picker-panels > *:last-child {
    display: none !important;
}

.ant-picker-panel-container, .ant-picker-footer {
    width: 280px !important;
}

.ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
}
