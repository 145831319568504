/* base */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 90%;
  width: 100%;
  color: #31302c;
}
