.user-avatar__front {
    z-index: 1;
}

.user-avatar__back {
    z-index: 0;
    object-fit: cover;
    transform: rotateY(180deg) ;
}

.user-avatar__card {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.user-avatar__front, .user-avatar__back {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.250s;
    backface-visibility: hidden;
}

.user-avatar__card:hover .user-avatar__front {
    transform: rotateY(180deg);
    transition-delay:200ms;
}

.user-avatar__card:hover .user-avatar__back {
    transform: rotateY(360deg);
    transition-delay:200ms;
}
